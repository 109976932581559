import { Routes } from '@angular/router';
import { PaymentStatusGuard } from './payment-status.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'en', pathMatch: 'full' },
  {
    path: ':lang/payment/success/:transactionId',
    pathMatch: 'full',
    loadComponent: () =>
      import('./Features/status-payment/status-payment.component').then(
        (mod) => mod.StatusPaymentComponent
      ),
    canActivate: [PaymentStatusGuard],
  },
  {
    path: ':lang/payment/cancel/:transactionId',
    pathMatch: 'full',
    loadComponent: () =>
      import('./Features/status-payment/status-payment.component').then(
        (mod) => mod.StatusPaymentComponent
      ),
  },
  {
    path: ':lang/transactions/:transactionId',
    pathMatch: 'full',
    loadComponent: () =>
      import('./Features/transactions/transactions.component').then(
        (mod) => mod.TransactionsComponent
      ),
  },
  { path: '**', redirectTo: 'en' },
];
